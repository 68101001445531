<template>
  <v-card
    class="mx-auto mt-16 mb-6"
    elevation="9"
    height="90%"
    width="55%"
  >
      <v-toolbar
        flat
      >

        <v-btn 
          @click="volver()"
          icon
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-toolbar>

      <v-avatar
        class="v-avatar--offset mx-auto profile mb-n10"
        size="250"
      >
        <v-img
          :src="integranteActual.img"
          transition="scale-transition"
        />
      </v-avatar>

      

    <v-card-text class="mt-n16">
      <div class="text-h6 mb-2">
        {{integranteActual.nombre}}
      </div>

      <div class="subheading font-weight-light grey--text">
        {{integranteActual.grado}}
      </div>

      <div class="subheading font-weight-light grey--text">
       {{integranteActual.cargo}}
      </div>

      <v-divider class="my-2"></v-divider>

      <v-icon
        class="mr-2"
      >
        mdi-clock
      </v-icon>

      <span class="text-caption grey--text font-weight-light"></span>
    </v-card-text>
  </v-card>
</template>

<script>

export default{
  name:'Member',
  data () {
    return {
      integranteActual: Object,
      integrantes: [
          {
            area: 'Mining and manufacturing applications',
            nombre: 'Gonzalo Acuña',
            email: 'gonzalo.acuna@usach.cl',
            grado:'PhD en Control Automático',
            cargo: 'Full Professor',
            img: 'https://www.portalminero.com/wp/wp-content/uploads/subidos/columnas/805_611d0bb45f1df.jpg',
            links: ['ads.com', 'asd.com', 'asd.com']
          },
          {
            area: 'Biomedical Image Processing and Classificacition',
            nombre: 'Violeta Chang',
            email: 'violeta.chang@usach.cl',
            grado: 'PhD Computer Science',
            cargo: 'Assistant Professor',
            img: 'https://vchang.diinf.usach.cl/retrato.jpg',
            links: ['ads.com', 'asd.com', 'asd.com']
          },
          {
            area: 'Neuroscience',
            nombre: 'Leonel Medina',
            email: 'leonel.medina@usach.cl',
            grado: 'PhD Biomedical Engineering',
            cargo: 'Assistant Professor',
            img: 'https://neuroeng.diinf.usach.cl/author/leo-medina/avatar_hu535661bce2c742bf7a0f6a055ac0b6d1_612694_270x270_fill_q90_lanczos_center.jpg',
            links: ['ads.com', 'asd.com', 'asd.com']
          },
          {
            nombre: 'Benjamín Morales',
            email: 'benjamin.morales@usach.cl',
            trabajo: 'Automatic grading of breast cancer biopsies using deep learning',
            img: '../assets/male1.png'
          },
          {
            nombre: 'Felipe Gonzáles',
            email: 'felipe.gonzales@usach.cl',
            trabajo: 'Impact of non-local features in human sperm segmentation',
            img: '../assets/male2.png'
          },
          {
            nombre: 'Benjamín Hernández',
            email: 'benjamin.hernandez@usach.cl',
            trabajo: 'Generation of gold-stardard of breast cancer biopsies',
            img: '../assets/male1.png'
          },
          {
            nombre: 'Pascale Cuevas',
            email: 'pascale.cuevas@usach.cl',
            trabajo: 'Detection of underwater debris using deep learning algorithms',
            img: '../assets/female2.png'
          },
          {
            nombre: 'Luis Abello',
            email: 'luis.abello@usach.cl',
            trabajo: 'Optimization of U-net architecture for human sperm segmentation',
            img: '../assets/male2.png'
          },
          {
            nombre: 'Javier Velásquez',
            email: 'javie.velasquez@usach.cl',
            img: '../assets/male1.png'
          },
          {
            nombre: 'Nicolás Román',
            email: 'nicolas.roman@usach.cl',
            img: '../assets/male2.png'
          },
          {
            nombre: 'Ruth Marin',
            email: 'ruth.marin@usach.cl',
            img: '../assets/female1.png'
          },
        ],  
    }
  },

  methods:{
    volver(){
      this.$router.go(-1)
    },
    cargarIntegrante(nombre){
      for(let i = 0; i < this.integrantes.length ; i++){
        if (this.integrantes[i].nombre == nombre.replace("_"," ")){
          this.integranteActual = this.integrantes[i]
        }
      }

      return 0;
    
    }
  },

  created (){
    this.cargarIntegrante(this.$route.params.member)
  }
  
}

</script>

<style>
  .v-avatar--offset {
    top: -120px;
    position: relative;
  }
</style>